@import '../../scss/_breakpoints.scss';

.ds-nav {
  --header-padding-left: 0;
  --header-padding-right: 0;
  --header-height: 50px;
  --gray-color: #eaeaea;

  display: grid;
  align-items: center;
  padding: 0 var(--header-padding-right) 0 var(--header-padding-left);
  border-bottom: solid 1px var(--gray-color);
  min-height: var(--header-height);
  grid-template-columns: max-content auto max-content;
  .mobile-login {
    display: none;
  }
  .desktop-login {
    display: block;
    text-align: center;
  }
  nav {
    grid-column: 1/3;
    font-size: 1em;
    padding: 0;
    ul {
      height: var(--header-height);
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: var(--spacer-4);
        font-size: 0.8125rem;
        height: 100%;
      }

      li {
        font-weight: 500;
        a {
          color: #58585a;
          font-weight: 500;
        }

        a:hover,
        &.active a {
          color: var(--primary-color-light);
        }

        &.active::after {
          position: absolute;
          content: '';
          width: 100%;
          bottom: 0;
          height: 1px;
          background-color: var(--primary-color-light);
          left: 0;
        }
      }
    }
  }

  &__utils {
    grid-column: 3/-1;
    position: relative;
    input[type='search'] {
      border: none;
    }
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 0.75em;

    .search {
      height: 50px;
      position: relative;
      padding: 0 var(--spacer-2);
      display: flex;
      align-items: center;
      justify-content: center;

      &__input {
        height: 30px;
        &::placeholder {
          color: var(--gray-color-50);
        }
      }

      &__button {
        position: relative;
        top: 3px;
        width: 15px;
        path {
          fill: #ccc;
        }
      }
    }

    .horizontal-divider {
      width: 2px;
      height: 15px;
      background-color: var(--gray-color);
      position: absolute;
      bottom: 0;
      padding: 0;
      opacity: 0.5;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }
  }
  @include breakpoint(md) {
    border: none;
    nav {
      font-size: 1em;
      font-weight: 300;
      ul li a {
        font-weight: 300;
        font-size: 0.875em;
      }
    }
    --header-padding-right: 0;
    display: block;
    .desktop-login {
      display: none;
    }
    .mobile-login {
      display: block;
      font-size: 1em;
      font-weight: 500;
      margin: var(--spacer-2) 0 var(--spacer-2) var(--spacer-1);
    }
    &__utils {
      display: block;
      .horizontal-divider {
        display: none;
      }
      .login-link,
      .search {
        display: block;
      }
      .search {
        padding: 0 var(--spacer-1);
        margin: var(--spacer-3) 0 var(--spacer-2);
        input[type='search'] {
          border: solid 1px var(--gray-color-light);
          padding: 8px;
          height: 40px;
          min-width: 300px;
          border-radius: var(--border-radius);
        }
      }
    }
  }
  @include breakpoint(sm) {
    &__utils {
      .search {
        input[type='search'] {
          width: calc(100% - 30px);
          min-width: 280px;
          margin-right: var(--spacer-1);
        }
      }
    }
  }
}
