@import '../../design-system/src/scss/_breakpoints.scss';
@import '../../design-system/src/scss/_mixins.scss';

.menu-section {
  ul {
    margin: 0;
  }
  li {
    line-height: 1.5;
    font-size: 1rem;
    padding-bottom: var(--spacer-4);
  }

  h4 {
    a {
      color: var(--primary-color-light);
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__description {
    line-height: 1.5;
    p {
      line-height: 1.5;
    }
  }

  &__normal {
    //margin-left:1rem!important;
    a {
      color: var(--text-color-default);
      font-weight: 500;

      &:hover {
        color: var(--primary-color-light);
      }
    }
  }

  &__highlighted {
    a {
      color: var(--primary-color-light);
      font-weight: 500;
      font-size: 1.125em;
    }
  }

  @include breakpoint(md) {
    li,
    a {
      color: var(--ds-color-text-base);
      font-weight: 300;
    }
    &__highlighted {
      a {
        font-weight: 500;
        &:after {
          content: '';
          display: none;
        }
      }
    }
    &:first-child {
      display: none; // hiding category description on mobile
    }
    ul {
      li {
        font-size: 0.875rem;
        margin-top: var(--spacer-1);
        padding-bottom: var(--spacer-2);
      }
    }
  }
}
footer {
  .menu-section {
    h4,
    p {
      display: none;
    }
    ul {
      margin: var(--spacer-3) 0 0 0;
    }
    li {
      @include footerLink();
      font-size: 0.875rem;
      a {
        color: var(--text-color-light);
        font-size: 0.875rem;
      }
    }
    &__highlighted {
      a {
        color: var(--ds-color-text-heading) !important;
        font-weight: 600 !important;
        &::after {
          content: '';
        }

        &:hover {
          color: var(--primary-color-light) !important;
        }
      }
    }
    @include breakpoint(sm) {
      li {
        padding-bottom: 0;
      }
    }
  }
}
