@import '../../design-system/src/scss/_breakpoints.scss';
.mega-menu {
  position: absolute;
  transition: all var(--animation-speed-slow) var(--animation-easing-cubic-in-out);
  opacity: 0;
  pointer-events: none;
  top: 99%;
  left: -100%;
  z-index: 2;
  transition-delay: 0.25s;
  transform-origin: center;
  transform: translateY(5px) scale(0.85);
  will-change: auto;

  &__content::before {
    content: '';
    position: absolute;
    margin-left: -5px;
    top: -18px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 20px solid #fff;
    border-color: transparent transparent white transparent;
  }

  &__content {
    margin-top: 30px;
    background-color: #fff;
    box-shadow: var(--shadow);
    width: 963px;
    height: 364px;
    padding: var(--spacer-9) var(--spacer-7);
    border-radius: 8px;
    display: grid;
    grid-template-columns: 262px max-content max-content;
    gap: 100px;
    font-weight: normal;
    position: relative;
    z-index: 9;

    div {
      position: relative;
      z-index: 9;
    }
  }
  @include breakpoint(md) {
    position: relative;
    opacity: 1;
    transform: scaleY(0);
    overflow: hidden;
    &__content {
      //margin-top: 0;
      box-shadow: none;
      width: 100%;
      height: auto;
      padding: 0;
      display: block;
    }
  }
}
