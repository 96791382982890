@import '../../design-system/src/scss/_mixins.scss';

.marketo-form {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.25);
  place-content: center;
  align-items: center;
  z-index: 100000011;
  display: none;

  &.visible {
    display: flex;
  }

  .close {
    position: absolute;
    top: -20px;
    right: 20px;
    background-color: transparent;
    box-shadow: none;
    color: #000;
    font-family: var(--ds-typography-body);

    &:hover {
      color: var(--primary-color);
      background-color: transparent;
    }
  }

  &__content {
    width: 90%;
    min-height: 500px;
    height: auto;
    max-height: 90%;
    overflow: auto;
    max-width: 800px;
    background-color: #fff;
    padding: 50px;
    position: relative;
    text-align:left;
  }

  @include formStyles;
}
