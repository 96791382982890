.speed-bump {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.25);
  place-content: center;
  align-items: center;
  display: flex;
  z-index: 100000011;

  &.visible {
    display: flex;
  }

  &__content {
    max-height: 600px;
    max-width: 700px;
    z-index: 100000011;

    background-color: #fff;
    padding: 50px;
    box-shadow: var(--shadow);
    text-align: center;

    h1 {
      font-size: 2rem;
      line-height: 1.5;
      margin-bottom: var(--spacer-4);
    }

    button {
      width: 100%;
      max-width: 100%;
      margin-bottom: var(--spacer-2);
    }
  }
}
