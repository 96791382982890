@import '../../scss/_breakpoints.scss';

.layout {
  --background-width: 800px;
  --background-negative: calc(var(--background-width) / 2);
  position: relative;

  @include breakpoint(md) {
    overflow-x: hidden;
  }

  &:not(.show-overflow) {
    overflow-x: hidden;
  }

  &:not(.none) {
    &.with-background::before {
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      top: 188px;
      height: 800px;
      width: var(--background-width);
      opacity: 0.2;
      overflow: visible;
      background-blend-mode: screen;
      // -webkit-mask-mode: alpha;
      // -webkit-mask-size: contain;
      // -webkit-mask-repeat: no-repeat;
      mask-mode: alpha;
      mask-size: contain;
      mask-repeat: no-repeat;
      z-index:3;

      @include breakpoint(sm) {
        top: 82px;
        height: 428px;
        width: 428px;
      }
    }

    &.blue::before {
      background-color: var(--ds-color-secondary);
    }

    &.green::before {
      background-color: var(--ds-color-tertiary);
    }

    &.orange::before {
      background-color: var(--ds-color-accent);
    }

    &.primaryLight::before {
      background-color: var(--ds-color-primary-light);
    }

    &.left::before {
      left: -340px;
      @include breakpoint(sm) {
        left: -179px;
      }
    }

    &.right::before {
      right: -340px;
      @include breakpoint(sm) {
        right: -179px;
      }
    }

    &.center::before {
      left: calc(50% - var(--background-negative));

      @include breakpoint(sm) {
        left: calc(50% - 214px);
      }
    }

    &.balloon::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621975675/new-site/balloon-hero.png);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
    }

    &.bullseye::before {
      // --background-image: url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621975675/new-site/hero-etching-bullseye.png);
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1623768433/new-site/bullseye_rld9ag.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.13;
    }

    &.moneybag::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621975901/new-site/money-bag.png);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.2;
    }

    &.compass::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976105/new-site/compass_gfdb3a.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.08;
    }

    &.lion::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976074/new-site/lion_eq0obk.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.2;
    }

    &.tree::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976077/new-site/tree_ooh3kd.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.2;
    }

    &.binoculars::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976074/new-site/binoculars_vzi7qy.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.2;
    }

    &.handshake::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976074/new-site/handshake_gowftf.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.2;
    }

    &.lightbulb::before {
      --background-width: 450px;
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976074/new-site/lightbulb_msa6mq.svg);
      --background-negative: calc(var(--background-width) / 2);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.1;

      @include breakpoint(sm) {
        left: calc(50% - 140px);
      }
    }

    &.lock::before {
      --background-width: 550px;
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976177/new-site/lock_xes1la.svg);
      --background-negative: calc(var(--background-width) / 2);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.15;
    }

    &.mountainhouse::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976074/new-site/mountainhouse_ssm7ry.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.15;
    }

    &.mountainroad::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976076/new-site/mountainroad_dsmlhs.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.12;
    }

    &.mountains::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/v1621976077/new-site/mountains_qoovcx.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      opacity: 0.12;
    }

    &.tree.left::before {
      left: -240px;
    }

    &.hand::before {
      height: 464px;
      width: 955px;
      top: 203px;

      left: calc(50% - 478px);

      @include breakpoint(sm) {
        left: 5%;
        width: 90%;
      }

      --background-image: url(https://media.firstbusiness.bank/image/upload/v1623773085/new-site/hand_t1vlw8.svg);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
    }
    &.plane::before {
      --background-image: url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621975675/new-site/paper-plane_c5cusi.png);
      background-image: var(--background-image);
      -webkit-mask-image: var(--background-image);
      mask-image: var(--background-image);
      left:0;
      top:-80px;
    }

  }
}
