.ds-tags {
  gap: var(--spacer-1);

  button {
    // width: 100%;
    // display: block;
    color: var(--primary-color-dark);
    font-size: 0.825rem;
  }
}
