.ds-loading,
.ds-loading:after {
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
}
.ds-loading {
  $color: rgba(0, 0, 0, 0.15);
  font-size: 0.625rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.35em solid $color;
  border-right: 0.35em solid $color;
  border-bottom: 0.35em solid $color;
  border-left: 0.35em solid var(--loading-color, var(--primary-color));
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
