@import '../../scss/_breakpoints.scss';

.ds-button {
  border: solid 1px transparent;
  box-shadow: var(--shadow);
  transition: all 0.25s linear;
  position: relative;
  height: var(--ds-button-height, auto);
  font-weight: 500;
  align-items: center;
  border-radius: var(--ds-button-border-radius, 0);
  width: auto;
  max-width: max-content;

  .root {
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--ds-button-padding-regular-horizontal);
    height: 58px;

    &.fade-out {
      opacity: 0;
    }
  }

  .root > span {
    display: inline-flex;
    align-items: center;
  }

  &__text {
    margin: 0 0.5em;
    font-size: var(--ds-button-font-size, 1rem);
  }

  &__icon {
    line-height: 0.5;
    font-size: 1.5em;
  }
  svg {
    height: 1em;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &__primary {
    background-color: var(--ds-color-primary);
    color: var(--ds-button-color-primary-foreground, #fff);
    &:hover,
    &:focus {
      background-color: var(--ds-color-primary-light);
    }

    .ds-loading {
      border-top: 0.25em solid rgba(255, 255, 255, 0.15);
      border-right: 0.25em solid rgba(255, 255, 255, 0.15);
      border-bottom: 0.25em solid rgba(255, 255, 255, 0.15);
      border-left: 0.25em solid rgba(255, 255, 255, 1);
    }
  }

  &__primary-light {
    background-color: var(--ds-color-primary-light);
    color: var(--ds-button-color-primary-foreground, #fff);
    &:hover,
    &:focus {
      background-color: var(--primary-color);
    }

    .ds-loading {
      border-top: 0.25em solid rgba(255, 255, 255, 0.15);
      border-right: 0.25em solid rgba(255, 255, 255, 0.15);
      border-bottom: 0.25em solid rgba(255, 255, 255, 0.15);
      border-left: 0.25em solid rgba(255, 255, 255, 1);
    }
  }

  &__secondary {
    background-color: var(--ds-button-color-secondary-background);
    color: var(--ds-color-text-heading);
    border-color: var(--ds-button-color-secondary-background);
    &:hover,
    &:focus {
      color: var(--primary-color);
    }
  }

  &__tertiary {
    color: var(--text-color-white);
    background-color: var(--accent-color);
    &:hover,
    &:focus {
      background-color: var(--accent-color-light);
    }
  }

  &.loading {
    .ds-loading {
      position: absolute;
      transform: translateX(-23px);
      left: calc(50% - 1.75em);
    }

    .ds-loading,
    .ds-loading:after {
      width: 2.5em;
      height: 2.5em;
    }
  }

  &__descructive {
    background-color: var(--error-color);
    color: var(--light-text-color, #fff);

    .ds-loading {
      border-top: 0.25em solid rgba(255, 255, 255, 0.15);
      border-right: 0.25em solid rgba(255, 255, 255, 0.15);
      border-bottom: 0.25em solid rgba(255, 255, 255, 0.15);
      border-left: 0.25em solid rgba(255, 255, 255, 1);
    }

    &:hover {
    }

    &:focus {
    }
  }

  &__hollow {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover,
    &:focus {
      background-color: #fff;
      border-color: #fff;
      color: var(--primary-color); //var(--ds-button-color-primary-foreground, #fff);
    }
  }

  &.display-block {
    width: 100%;
  }

  &.submit {
  }

  &__small {
    font-size: 0.75em;
    .root {
      padding: var(--ds-button-padding-small-vertical) var(--ds-button-padding-small-horizontal);
      height: auto;
    }
  }

  &__medium {
    font-size: 1em;
  }

  &__large {
    font-size: 1.25em;
    .root {
      padding: var(--ds-button-padding-large-vertical) var(--ds-button-padding-large-horizontal);
    }
  }

  &.icon {
    &.left {
    }

    &.right {
    }
  }
  @include breakpoint(sm) {
    max-width: 100%;
  }
}
