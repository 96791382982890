@import '../../scss/_breakpoints.scss';

.ds-image {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  @include breakpoint(md) {
    margin-left:auto;
    margin-right:auto;
  }
}
.ds-image-grid{
  @include breakpoint(sm) {
      flex-direction:column;
      .ds-image {
          margin-bottom:var(--spacer-3);
      }
  }
}
