.ds-icon {
  --icon-size: 1em;
  font-size: var(--icon-size);
  line-height: 1;
  &.large {
    font-size: 1.5em;
    line-height: 0;
  }
}
.podcast-logo {
  
  svg {
    height:125px;
    width:auto;
    max-width:100%;
  }
}
