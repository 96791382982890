.ds-banner-alert {
  background-color: var(--primary-color-light);
  min-height: 55px;
  color: #fff;
  padding: var(--spacer-2);
  position: relative;
  display: flex;
  align-items: center;
  z-index:100;

  &__text {
    max-width: 1300px;
    position: relative;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: rgb(255, 255, 255, 0.5);
    }
  }

  &.display-none {
    display: none;
  }

  button {
    position: absolute;
    right: var(--spacer-2);
    top: calc(50% - 7px);
    cursor: pointer;
  }
}
