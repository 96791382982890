@import '../../scss/_mixins';

.ds-tag {
  display: inline-flex;
  padding: 0 12px;
  background-color: var(--primary-color-lighter);
  border-radius: 3px;
  font-size: 0.8125rem;
  color: #fff;
  font-weight: 500;
  min-width: max-content;

  @include selectFontColor(var(--text-color-default));

  &.dark {
  }

  &.accent {
  }
}

a.ds-tag {
  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
}
