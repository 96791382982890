@import '../../design-system/src/scss/_breakpoints.scss';
@import '../../design-system/src/scss/_mixins.scss';

$width: 28px;
$height: 1px;
$margin: 6px;

.ds-hamburger {
  width: 30px;
  position: absolute;
  right: var(--spacer-4);
  top: 34px;
  z-index: 20;

  .lines {
    overflow: visible;
    transition: all 0.1s ease;
    transform-origin: center;
    .line {
      width: $width;
      height: $height;
      background-color: var(--primary-color-light);
      transition: all 0.125s var(--animation-easing-cubic-out);
      transform-origin: left;
      border-radius: 50px;
      fill: var(--primary-color-light);
      will-change: auto;
    }

    .line-1,
    .line-3 {
      transition: all var(--animation-speed-normal) var(--animation-easing-circ-out);
      transform: scaleX(0.67);
    }

    @include hasHover() {
      &:hover {
        .line-1,
        .line-3 {
          transform: scaleX(1);
        }
      }
    }

    &.active {
      .line {
        transition-delay: 0;
        transition-timing-function: var(--animation-easing-cubic-in);
        transform-origin: center;
        fill: var(--primary-color);
      }

      .line-1 {
        transform: translatey($height + $margin) translateX(-4px) rotate(45deg) scaleX(1);
        transition-delay: 0.2s;
      }
      .line-2 {
        transform: scaleX(0) translateX(-4px);
        opacity: 0.25;
      }
      .line-3 {
        transform: translatey(-($height + $margin)) translateX(-4px) rotate(-45deg) scaleX(1);
        transition-delay: 0.2s;
      }
    }
  }
}
