@import '../../scss/_breakpoints.scss';

.ds-flex {
  --container-width: calc(100% - var(--spacer-6));
  --container-max-width: 1400px;
  --container-width-wide: 1370px;
  --flex-gap: 32px;
  gap: var(--flex-gap);
  width: var(--container-width);
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
}
.space-between {
  justify-content: space-between;
}
