@import '../../scss/_breakpoints.scss';

.ds-grid {
  --grid-size: 2;
  --grid-gap: 12px;
  --grid-row-gap: 12px;
  --grid-column-gap: 12px;
  --grid-element-size: 1fr;
  grid-template-columns: repeat(
    var(--grid-size),
    var(--grid-element-size)
  ); //grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
  //grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

  @include breakpoint(md) {
    --grid-row-gap: 0 !important;
  }
}
