@import '../../scss/_breakpoints.scss';

.ds-container {
  --container-width: calc(100% - var(--spacer-6));
  --container-max-width: 1137px;
  --container-width-wide: 1370px;
  position: relative;

  z-index: 4;

  width: var(--container-width);
  max-width: var(--container-max-width);

  &--full-height {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &--full-width {
    --container-width: 100%;
    --container-max-width: 100%;
  }

  &--narrow {
    --container-width: 906px;
    --container-max-width: 95%;
  }

  &--wide {
    --container-max-width: var(--container-width-wide);
  }

  &--light {
    background-color: transparent;
  }

  &--dark {
    color: var(--ds-color-text-light);
    background-color: var(--text-color-default);
  }

  &--accent {
    background-color: var(--ds-color-primary);
    background-color: var(--ds-color-text-base);
  }

  &--shadow {
    border-radius: 10px;
    box-shadow: var(--shadow);
    overflow: hidden;
  }
  @include breakpoint(md) {
    &--narrow {
      --container-width: 100%;
    }
  }
}
