@import '../../design-system/src/scss/_breakpoints.scss';
@import '../../design-system/src/scss/_mixins.scss';

.ds-header {
  --nav-item-size: 3;
  --column-gap: 40px;
  --header-padding-top: var(--spacer-3);
  --header-padding-left: 0;
  --header-padding-right: 0;
  --header-height: 100px;
  position: relative;
  margin-right: 180px;

  padding: var(--header-padding-top) var(--header-padding-right) 0 var(--header-padding-left);
  grid-template-columns: min-content auto max-content;
  min-height: var(--header-height);

  .logo {
    width: 178px;
    position: relative;
    z-index: 20;
  }

  .nav {
    display: flex;
    grid-column: 2/3;
    justify-content: center;
    align-items: center;
    .ul {
      grid-template-columns: repeat(var(--nav-item-size), auto);
      column-gap: var(--column-gap);
      margin-bottom: 0;
      position: relative;
      z-index: 100000010;

      .li {
        position: relative;
        font-weight: 700;
        font-size: 1rem;
        z-index: 10;
        transition: color var(--animation-speed-fast) ease 0.35s;

        & > .toggle {
          color: var(--text-color-default);
          display: inline-block;
          position: relative;
          z-index: 4;
          font-weight: 500;
          cursor: pointer;
          @include breakpoint(md) {
            width: calc(100% - 40px);
            text-align: left;
          }
        }
        @include hasHover {
          &:hover > .toggle {
            color: var(--primary-color-light);

            &::after {
              border-top-color: #3b3e41;
            }
          }
        }

        &.active {
          & > .toggle {
            color: var(--primary-color-light);
          }

          &::after {
            position: absolute;
            content: '';
            width: 38px;
            bottom: -8px;
            height: 1px;
            background-color: var(--primary-color-light);
            left: calc(50% - 19px);
            @include breakpoint(md) {
              display: none;
            }
          }
        }

        &:nth-child(1) {
          .mega-menu {
            left: -162px;
          }

          .mega-menu__content::before {
            left: 212px;
          }
        }

        &:nth-child(2) {
          .mega-menu {
            left: -328px;
          }

          .mega-menu__content::before {
            left: 400px;
          }
        }

        &:nth-child(3) {
          .mega-menu {
            left: -540px;

            &__content::before {
              left: 617px;
            }
          }
        }

        @include hasHover {
          &.open {
            .mega-menu {
              opacity: 1;
              pointer-events: all;
              z-index: 10;
              transform: translateY(0) scale(1);
            }
          }
        }
        /* hasHover no longer working for iPad - leaving it in for other devices but adding non-media query as well */
        &.open {
          .mega-menu {
            opacity: 1;
            pointer-events: all;
            z-index: 10;
            transform: translateY(0) scale(1);
          }
        }

      }

      .li > .toggle::after {
        content: '';
        border-top: 6px solid #eaeaea;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        font-size: 1em;
        position: absolute;
        right: -1.25em;
        font-weight: 600;
        width: 8px;
        height: 3px;
        line-height: 1;
        top: 40%;
        transition: all var(--animation-speed-fast) ease;
        @include breakpoint(md) {
          right: 0;
          border-top: 6px solid var(--primary-color-light);
          display: none;
        }
      }

      .expand-button {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: -5px;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
        display: none;
        &:focus {
          outline: none;
        }
        .triangle {
          content: '';
          width: 8px;
          height: 3px;
          display: block;
          border-top: 6px solid var(--primary-color-light);
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
        @include breakpoint(md) {
          display: flex;
        }
      }
    }

    .hamburger {
      color: var(--text-color-default);
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }
  }

  @include breakpoint(md) {
    --header-padding-right: 0;
    margin-right: 0;

    & {
      position: fixed;
      height: auto;
      overflow: hidden;
      &[open] {
        position: relative;
        height: auto;
        overflow: auto;
        transition: all 0.5s ease-in-out;
      }
      left: 0;
      top: 0;
      width: 100%;
      padding: 0;
      display: block;
      .nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        grid-column: 1/3;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: var(--spacer-6);
        max-width: 100%;
        ul {
          display: block;
          width: 100%;

          li {
            margin-top: var(--spacer-4);

            a {
              display: block;
              width: 100%;
            }
            &.expanded {
              .mega-menu {
                height: auto;
                overflow: visible;
                pointer-events: all;
              }
            }
          }
        }
      }
      .cta {
        margin-top: var(--spacer-4);
      }
    }

    .logo {
      img {
        width: 110px;
      }
    }

    & {
      overflow-x: scroll;
      .nav {
        .ul {
          > .li {
            .mega-menu {
              left: 0 !important;
              margin-top: var(--spacer-2);
              transform: scaleY(0);
              transform-origin: top;
              overflow: hidden;
              max-height: 0;
              opacity: 0;
              transition: all var(--animation-speed-fast) var(--animation-easing-sine-out);
            }
            .mega-menu__content::before {
              left: 0 !important;
            }
            &.expanded {
              .triangle {
                transform: rotate(180deg);
              }
              .mega-menu {
                transform: scaleY(1);
                overflow: visible;
                max-height: none;
                transition: all var(--animation-speed-fast) linear;
                opacity: 1;
              }
            }
            &[open] {
              .mega-menu {
                transform: scaleY(1);
                overflow: visible;
                max-height: none;
                transition: all var(--animation-speed-fast) linear;
              }
            }
          }
        }
        ul li {
          margin-top: var(--spacer-1);
        }
      }
    }
  }
  @include breakpoint(sm) {
    & {
      .nav {
        max-width: none;

        button {
          text-align: left;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;
  left: -10000px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s;

  &.open {
    opacity: 1;
    left: 0;
  }
}
